import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';
import CallTollFree from '../../../components/Shared/CallTollFree/CallTollFree';
import Input from '../../../components/UI/Input/Input';
import MobileAlert from '../../../components/Shared/MobileAlert/MobileAlert';
import AgentsPrompt from '../AgentsPrompt/AgentsPrompt';
import styles from './OauthData.module.css';
import * as api from '../../../api/Oauth';
import { checkAgentEmail } from '../../../api/SignIn';
import { detectIE, detectMobile } from '../../../utils/utils';
import { checkValidity, updateObject } from '../../../shared/utility';

const OauthData = () => {
  const [inputsScheme, setInputScheme] = useState({
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'Enter your email',
      },
      value: '',
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Email:',
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Enter your password',
      },
      value: '',
      validation: {
        required: true,
        minLength: 6,
      },
      valid: false,
      error: '',
      touched: false,
      label: 'Password:',
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [nonFieldErrors, setNonFieldErrors] = useState(null);
  const [goBackUrl, setGoBackUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [showMobileAlert, setShowMobileAlert] = useState(detectMobile());
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const redirectURISearchParam = searchParams.get('redirect_uri');
  const clientIdSearchParam = searchParams.get('client_id');
  const vendorUserIdSearchParam = searchParams.get('vendor_user_id');
  const typeSearchParam = searchParams.get('type');
  const scopeSearchParam = searchParams.get('scope');
  const stateSearchParam = searchParams.get('state');

  const fetchAgents = useCallback(async (email) => {
    setIsLoading(true);
    try {
      const response = await checkAgentEmail(email);
      setAgents(response.data.login_data_list);
    } catch (e) {
      setNonFieldErrors(e.message);
    }
    setIsLoading(false);
  }, []);

  const clearState = useCallback(() => {
    setNonFieldErrors(null);
    setGoBackUrl(null);
    setAgents(null);
    setSelectedAgentId(null);
  }, []);

  const handleOnAgentsPromptClose = useCallback(() => {
    clearState();
  }, [clearState]);

  const handleOnClickLoginOption = (agentId) => {
    setSelectedAgentId(agentId);
  };

  const handleOnClickSignUpOption = useCallback(() => {
    history.push('/sign-up/');
  }, [history]);

  const handleOnShowMobileAlertClose = useCallback(() => {
    setShowMobileAlert(false);
  }, [setShowMobileAlert]);

  const inputChangedHandler = (inputValue, inputIdentifier) => {
    const validationInfo = checkValidity(
      inputValue,
      inputsScheme[inputIdentifier].validation
    );
    const updatedFormElement = updateObject(inputsScheme[inputIdentifier], {
      value: inputValue,
      valid: validationInfo.isValid,
      error: validationInfo.error,
      touched: true,
    });

    const updatedForm = updateObject(inputsScheme, {
      [inputIdentifier]: updatedFormElement,
    });

    let formIsValidCheck = true;
    Object.keys(updatedForm).forEach((inputIdentifier) => {
      formIsValidCheck = updatedForm[inputIdentifier].valid && formIsValidCheck;
    });

    setInputScheme(updatedForm);
    setFormIsValid(formIsValidCheck);
  };

  const formSubmittedHandler = (event) => {
    event.preventDefault();
    clearState();
    fetchAgents(inputsScheme.email.value);
  };

  useEffect(() => {
    if (selectedAgentId || (agents && agents.length <= 1)) {
      const data = {
        email: inputsScheme.email.value,
        password: inputsScheme.password.value,
        redirect_uri: redirectURISearchParam,
        client_id: clientIdSearchParam,
      };
      if (selectedAgentId) {
        data.pin = selectedAgentId;
      }
      if (typeSearchParam) {
        data.type = typeSearchParam;
      }
      if (vendorUserIdSearchParam) {
        data.vendor_user_id = vendorUserIdSearchParam;
      }
      if (scopeSearchParam) {
        data.scope = scopeSearchParam;
      }
      if (stateSearchParam) {
        data.state = stateSearchParam;
      }

      setIsLoading(true);
      if (history.location.pathname === '/oauth/zapier_authorize/') {
        api
          .authorizeZapier(data)
          .then((response) => {
            setIsLoading(false);
            window.location.href = response.data.redirect_uri;
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.response) {
              setNonFieldErrors(error.response.data.error);
            } else {
              setNonFieldErrors(error.message);
            }
          });
      } else {
        api
          .authorize(data)
          .then((response) => {
            setIsLoading(false);
            window.location.href = response.data.redirect_uri;
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.response) {
              setNonFieldErrors(error.response.data.error);
              setGoBackUrl(error.response.data.go_back_uri);
            } else {
              setNonFieldErrors(error.message);
            }
          });
      }
      clearState();
    }
  }, [
    agents,
    selectedAgentId,
    inputsScheme.email.value,
    inputsScheme.password.value,
    redirectURISearchParam,
    clientIdSearchParam,
    typeSearchParam,
    vendorUserIdSearchParam,
    scopeSearchParam,
    stateSearchParam,
    history.location.pathname,
    clearState,
  ]);

  useEffect(() => {
    if (detectIE()) {
      window.location.href = '/browser_select/';
    }
  }, []);

  const formElementsArray = Object.keys(inputsScheme).map((key) => {
    return {
      id: key,
      config: inputsScheme[key],
    };
  });

  return (
    <div className={styles.SignInData}>
      {showMobileAlert && (
        <MobileAlert onClose={handleOnShowMobileAlertClose} />
      )}
      {agents && agents.length > 1 && (
        <AgentsPrompt
          isOpen
          agents={agents}
          optionBtnLabel="Login"
          onClose={handleOnAgentsPromptClose}
          onLoginOptionClick={handleOnClickLoginOption}
          onSignUpOptionClick={handleOnClickSignUpOption}
        />
      )}
      <div className={styles.Title}>Sign In to Mojo</div>
      <div className={styles.SignInDataInner}>
        <div className={styles.SubTitle}>Sign In:</div>
        <form autoComplete="off" onSubmit={formSubmittedHandler}>
          {formElementsArray.map((formElement) => (
            <Input
              key={formElement.id}
              name={formElement.id}
              label={formElement.config.label}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              changed={(event) =>
                inputChangedHandler(event.target.value, formElement.id)
              }
              error={formElement.config.error}
            />
          ))}
          <Button
            type="submit"
            isBtnNext
            style={{ marginTop: '30px', width: '146px' }}
            isLoading={isLoading}
            disabled={!formIsValid}
          >
            Submit
          </Button>
          <a
            className={styles.ForgotYourPin}
            href="/password-reset/"
            style={{ marginLeft: '22px' }}
          >
            Forgot your Password?
          </a>

          {nonFieldErrors && (
            <div className={styles.NonFieldErrors}>{nonFieldErrors}</div>
          )}
          {nonFieldErrors && goBackUrl && (
            <Button
              type="button"
              style={{ marginTop: '20px', width: '166px' }}
              onClick={() => {
                window.location.href = goBackUrl;
              }}
            >
              Back to API Nation
            </Button>
          )}
        </form>

        <CallTollFree />
      </div>
    </div>
  );
};

export default OauthData;
