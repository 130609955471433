import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import styles from './SignUpData.module.css';
import StepOne from './StepOne/StepOne';
import StepTwo from './StepTwo/StepTwo';
import StepThree from './StepThree/StepThree';
import StepFour from './StepFour/StepFour';
import StepFive from './StepFive/StepFive';

const isProd = window.location.hostname === 'lb11.mojosells.com';
const trackingId = isProd ? 'UA-10532389-12' : 'UA-10532389-11';
const conversionTrackingId = isProd
  ? 'AW-984396631/Fpk5CPnI-c0BENfmstUD'
  : 'AW-984396631/x5g4CN63gbMDENfmstUD';
console.log('-conversionTrackingId', conversionTrackingId);
console.log('-trackingId', trackingId);

ReactGA.initialize(trackingId);

const onSendGAEvent = (event) => {
  ReactGA.event({
    category: 'general',
    action: event,
  });
};

const onSendGAConversion = (
  email,
  phones,
  firstName,
  lastName,
  address,
  city,
  state,
  country,
  postalCode,
  totalPrice
) => {
  if (conversionTrackingId) {
    window.CONVERSION_EMAIL = email;
    window.CONVERSION_PHONES = phones;
    window.CONVERSION_FIRST_NAME = firstName;
    window.CONVERSION_LAST_NAME = lastName;
    window.CONVERSION_ADDRESS = address;
    window.CONVERSION_CITY = city;
    window.CONVERSION_STATE = state;
    window.CONVERSION_COUNTRY = country;
    window.CONVERSION_POSTAL_CODE = postalCode;

    window.gtag('event', 'conversion', {
      send_to: conversionTrackingId,
      value: totalPrice,
      currency: 'USD',
    });
  }
};

const onRenameGAEvent = (str) => {
  let event = '';
  switch (str) {
    case 'single_user':
      event = 'Single User';
      break;
    case 'multi_user':
      event = 'Multi User';
      break;
    case 'data_and_dialer':
      event = 'Dialer and Data';
      break;
    case 'dialer':
      event = 'Dialer';
      break;
    case 'data':
      event = 'Data';
      break;
    default:
      event = '';
      break;
  }
  return event;
};

const SignUpData = (props) => {
  const { match } = props;
  const { stepNum } = match.params;
  const location = useLocation();

  const titleText = `Sign Up (${stepNum} of 5)`;

  const step = (
    <Switch>
      <Route
        path="/sign-up/step-1"
        exact
        render={(props) => <StepOne {...props} sendGAEvent={onSendGAEvent} />}
      />
      <Route
        path="/sign-up/step-2"
        exact
        render={(props) => (
          <StepTwo
            {...props}
            sendGAEvent={onSendGAEvent}
            renameGAEvent={onRenameGAEvent}
          />
        )}
      />
      <Route
        path="/sign-up/step-3"
        exact
        render={(props) => (
          <StepThree
            {...props}
            sendGAEvent={onSendGAEvent}
            renameGAEvent={onRenameGAEvent}
          />
        )}
      />
      <Route
        path="/sign-up/step-4"
        exact
        render={(props) => (
          <StepFour
            {...props}
            sendGAEvent={onSendGAEvent}
            renameGAEvent={onRenameGAEvent}
          />
        )}
      />
      <Route
        path="/sign-up/step-5"
        exact
        render={(props) => (
          <StepFive
            {...props}
            sendGAEvent={onSendGAEvent}
            renameGAEvent={onRenameGAEvent}
            sendGAConversion={onSendGAConversion}
          />
        )}
      />
      <Redirect to="/sign-up/step-1" />
    </Switch>
  );

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <div className={styles.SignUpData}>
      <div className={styles.Title}>{titleText}</div>
      {step}
    </div>
  );
};

const mapStateToProps = () => {
  return {
    // stepNumber: state.signUp.stepNumber,
  };
};

export default connect(mapStateToProps)(SignUpData);
